import Datepicker from "react-tailwindcss-datepicker";
import "./style.css";

type Props = {
    startDate: Date;
    setDate: (date: Date) => void;
}

const DateRange: React.FC<Props> = ({ startDate, setDate }) => {
    const value = {
        startDate: startDate,
        endDate: startDate
    }
    const setValue = (value: any) => {
        if (value.startDate === null) {
            return;
        }
        setDate(value.startDate);
    }
    return (<div className="flex flex-col gap-3">
        <div className="w-fit border rounded">
            <Datepicker value={value} onChange={setValue} asSingle startWeekOn="mon" />
        </div>
    </div>)
}

export default DateRange;