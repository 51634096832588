type Props = {
    legend: string;
    prevWeek: () => void;
    nextWeek: () => void;
}

const DateNavigator: React.FC<Props> = ({ prevWeek, nextWeek, legend }) => {
    return (
        <div className="flex flex-row gap-3 justify-between items-center text-lg">
            <button onClick={prevWeek}>&larr;</button>
            <div className="text-xs">{legend}</div>
            <button onClick={nextWeek}>&rarr;</button>
        </div>
    )
}

export default DateNavigator;