import { useState } from "react";
import SchoolSelection from "./SchoolSelection";
import DateRange from "./DateRange";
import LogTable from "./LogTable";
import DateNavigator from "./DateNavigator";
import { Day } from "@/lib/core/day";
import { SchoolAssociationOutput } from "@/hooks/useSchoolAssociations";
import { useInstructionalLog } from "@/hooks/useInstructionalLog";
import Loading from "../ui/loading";
import { useMaxCurriculum } from "@/hooks/useMaxCurriculum";

// TODO: Decompose into objects rather than everything at the top level
type Props = {
    startDate: Date;
    school: string; // TODO
    // Instructor/Student pairs
    holidays: Day[];
    setDate: (date: Date) => void;
    students: SchoolAssociationOutput | undefined
}

type LoadableLogProps = {
    selectedSchool: string;
    startDate: Date;
    endDate: Date;
    holidays: Day[];
    students: SchoolAssociationOutput | undefined;
}


const LoadableLog: React.FC<LoadableLogProps> = ({
    startDate,
    endDate,
    selectedSchool,
    holidays,
    students,
}) => {
    const instructionalLog = useInstructionalLog(selectedSchool, startDate);
    const latestCurriculumByStudent = useMaxCurriculum(selectedSchool);

    if (!instructionalLog.data) {
        return <Loading {...instructionalLog} />
    }

    if (!latestCurriculumByStudent.data) {
        return <Loading {...latestCurriculumByStudent} />
    }

    return (<LogTable
        upsert={instructionalLog.upsert}
        latestCurriculumByStudent={latestCurriculumByStudent.data ?? new Map()}
        startDate={Day.fromDate(startDate)}
        endDate={Day.fromDate(endDate)}
        holidays={holidays}
        students={students}
        log={instructionalLog.data.entries}
        setLog={(_prev: any) => { }} />)
}

const Log: React.FC<Props> = ({ startDate, school, holidays, setDate, students }) => {
    const [selectedSchool, setSelectedSchool] = useState(school);

    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 13);

    const prevWeek = () => {
        const d = new Date(startDate);
        d.setDate(d.getDate() - 14);
        setDate(d);
    }
    const nextWeek = () => {
        const d = new Date(startDate);
        d.setDate(d.getDate() + 14);
        setDate(d);
    }

    if (!students) {
        return <div><Loading error={undefined} isError={false} isPending={false} isLoading={!students} /></div>
    }

    return (
        <div className="flex flex-col gap-6">
            <div className="max-w-fit"><SchoolSelection schools={students?.schools} selectedSchool={selectedSchool} setSelectedSchool={setSelectedSchool} /></div>
            <DateRange startDate={startDate} setDate={setDate} />
            <LoadableLog
                selectedSchool={selectedSchool}
                startDate={startDate}
                endDate={endDate}
                holidays={holidays}
                students={students} />
            <DateNavigator prevWeek={prevWeek} nextWeek={nextWeek} legend={"s = student absence, i = instructor absence, o = other responsibility"} />
        </div>
    )
}

export default Log;