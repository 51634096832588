import { useCallback } from "react";
import Dialog, { openDialog } from "../Dialog";

type Props = {
    day: string,
    student_id: string,
    log_id: string | undefined,
    comment: string,
    onChange?: (id: string, log_id: string | undefined, comment: string | undefined) => void,
    onSubmit?: (id: string, log_id: string | undefined, comment: string | undefined) => void,
}

const Comment: React.FC<Props> = ({ day, student_id, log_id, comment, onChange, onSubmit }) => {
    const commentDialogId = `log-comment-${student_id}-${day}`;
    const onCommit = useCallback((_event: any) => {
        onSubmit?.(day, log_id, comment);
    }, [comment, day, log_id, onChange]);

    const onInputChange = (ev: any) => {
        onChange?.(day, log_id, ev.target.value);
    };

    return (
        <>
            <Dialog id={commentDialogId} title="Add Comment" showCloseButton={false}>
                <form method="dialog" onSubmit={onCommit}>
                    <input className="py-1 px-3" type="text"
                        onChange={onInputChange}
                        value={comment}
                        placeholder="Comment" maxLength={140} autoFocus />
                </form>
            </Dialog >
            <button className="absolute top-0 right-0 text-ponce" onClick={_ => openDialog(commentDialogId)}>
                <svg height="10" width="10"><polygon points="0,0 10,10 10,0" fill="currentColor" /></svg>
            </button>
        </>
    )

}

export default Comment;