type Props = {
    schools: Map<string, string>
    selectedSchool: string;
    setSelectedSchool: (school: string) => void;
}

const commonClasses = "flex flex-col border border-gray-300 rounded-md p-3";

const SchoolSelection: React.FC<Props> = ({ schools, selectedSchool, setSelectedSchool }) => {
    if (!schools) {
        return null;
    }
    // TODO: Replace with standard dropdown
    return (
        <div className={commonClasses}>
            <select id="school" className="outline-none px-1" value={selectedSchool} onChange={(e) => setSelectedSchool(e.target.value)}>
                {[...schools.entries()].map(([school_id, school_name]) => (
                    <option key={school_id} value={school_id}>{school_name}</option>
                ))}
            </select>
        </div>
    )
}

export default SchoolSelection;