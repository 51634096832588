import { useQuery } from "@tanstack/react-query";
import { authorizedBackendGet } from "@/lib/backend";

export type Objective = {
  id: number;
  objective: string;
  is_active: boolean;
  is_onboarding: boolean;
};

const QUERY_KEY = ["Objectives"];

const fetchObjectives = async (): Promise<Objective[]> => {
  const { data } = await authorizedBackendGet<{ objectives: Objective[] }>("/objectivesMetadata");
  return data.objectives;
};

export const useObjectives = () => {
  return useQuery<Objective[], Error>({
    queryKey: QUERY_KEY,
    queryFn: fetchObjectives,
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};
