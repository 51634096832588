import { InstructionalLogEntry } from "@/hooks/useInstructionalLog";
import { InstructorStudentMap, StudentInstructorMap } from "@/hooks/useSchoolAssociations";
import { Day } from "@/lib/core/day";

export function zeroPad(num: number): string {
    return num.toString().padStart(2, '0');
}

export function dayOfWeek(date: Day): string {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.dayOfWeek()];
}

export function formatDateShort(date: Day): string {
    return `${zeroPad(date.month)}/${zeroPad(date.day)}`;
}

export function formatDateWithYear(date: Date): string {
    return `${zeroPad(date.getMonth() + 1)}/${zeroPad(date.getDate())}/${date.getFullYear()}`;
}

export function nextWeekday(date: Date): Date {
    const d = new Date(date);
    const day = d.getDay();
    if (day === 6) {
        d.setDate(d.getDate() + 2); // Saturday -> Monday
    } else if (day === 0) {
        d.setDate(d.getDate() + 1); // Sunday -> Monday
    }
    return d;
}


type AverageData = {
    total: number;
    completed: number;
}

type AverageDataMap = {
    [key: string]: AverageData;
}

type AverageCalculation = {
    school: AverageDataMap;
    [key: string]: AverageDataMap;
}

export type AverageDataMapProcessed = {
    [key: string]: string;
}

export type AverageCalculationProcessed = {
    school: AverageDataMapProcessed;
    [key: string]: AverageDataMapProcessed;
}

export type LogData = Map<string, Map<string, InstructionalLogEntry>>;

function averageDataMapToPercentString(data: AverageDataMap): AverageDataMapProcessed {
    return Object.fromEntries(Object.entries(data).map(([date, { total, completed }]) => [date, asPercentString(total > 0 ? completed / total : 0)]))
}

export function asPercentString(num: number): string {
    return (num * 100.0).toFixed(0) + "%";
}

export function calculateStudentAverage(startDate: Day, endDate: Day, studentCountByInstructor: Map<string, number>, studentInstructorMap: StudentInstructorMap, log: LogData): AverageCalculationProcessed {
    let calculation: AverageCalculation = { school: {} };
    startDate.callOverRange(endDate, date => {
        // Skip Weekends
        if (date.isWeekend()) {
            return;
        }

        const studentCount = Array.from(studentCountByInstructor.values()).reduce((a, b) => a + b, 0);

        const ds = date.toString();
        if (!calculation.school[ds]) {
            calculation.school[ds] = { total: studentCount, completed: 0 }
        }

        [...studentCountByInstructor.entries()].forEach(([instructor, students]) => {
            if (!calculation[instructor]) {
                calculation[instructor] = {};
            }
            calculation[instructor][ds] = { total: students, completed: 0 };
        });
    })
    for (const [student_id, student_logs] of log.entries()) {
        for (const [date, log] of student_logs.entries()) {
            if (!log) {
                continue;
            }
            if (!calculation.school[date]) {
                continue;
            }
            const instructor = studentInstructorMap.get(student_id);
            if (!instructor) {
                continue;
            }
            if (Number.parseFloat(log.value)) {
                calculation.school[date].completed++;
                for (const instructor_id of instructor) {
                    calculation[instructor_id][date].completed++;
                }
            }
        }
    }
    return {
        school: averageDataMapToPercentString(calculation.school),
        ...Object.fromEntries(Object.entries(calculation).map(([instructor, data]) => [instructor, averageDataMapToPercentString(data)]))
    }
}

export function getStudentCountByInstructor(students: InstructorStudentMap | undefined): Map<string, number> {
    const studentCountByInstructor = new Map<string, number>();
    if (students) {
        for (const [instructor, data] of students.entries()) {
            studentCountByInstructor.set(instructor, data.size);
        }
    }
    return studentCountByInstructor;
}

export function getInstructorsById(students: InstructorStudentMap | undefined): Map<string, string> {
    const instructorsById = new Map<string, string>();
    if (students) {
        for (const [instructor, data] of students.entries()) {
            const first = data.keys().next().value;
            if (!first) {
                continue;
            }
            const name = data.get(first)?.instructor_name;
            if (name) {
                instructorsById.set(instructor, name);
            }
        }
    }

    return instructorsById;
}

export function swapStudentInstructorMapping(students: InstructorStudentMap | undefined): StudentInstructorMap | undefined {
    if (!students) {
        return undefined;
    }

    const studentInstructorMap: StudentInstructorMap = new Map();
    for (const [instructor, data] of students.entries()) {
        for (const [student, _association] of data.entries()) {
            if (!studentInstructorMap.has(student)) {
                studentInstructorMap.set(student, new Set());
            }
            studentInstructorMap.get(student)?.add(instructor);
        }
    }

    return studentInstructorMap;
}