import { useQuery } from "@tanstack/react-query";
import { authorizedBackendGet } from "@/lib/backend";

export type MaxCurriculum = {
    student_id: string;
    value: string;
}

export type MaxCurriculumOutput = Map<string, string>;

const QUERY_KEY = ["max_curriculum"];

const convertToOutput = (data: MaxCurriculum[]): MaxCurriculumOutput => {
    if (data) {
        return new Map(data.map(({ student_id, value }) => [student_id, value]));
    }
    return new Map()
}

const maxCurriculumFetch = async (school_id: string): Promise<MaxCurriculumOutput> => {
    const url = `/schools/${school_id}/max_curriculum`;
    const { data } = await authorizedBackendGet<{ curriculum: MaxCurriculum[] }>(url);
    return convertToOutput(data.curriculum)
};

export const useMaxCurriculum = (school_id: string) => {
    return useQuery<MaxCurriculumOutput, Error>({
        queryKey: ["instructional_log", school_id].concat(QUERY_KEY),
        queryFn: () => maxCurriculumFetch(school_id),
        staleTime: 1000 * 60 * 60, // 1 hour,
        retry: false
    });
};
