import Axios from "axios";

export async function authorizedBackendPost<T>(path: string, body: T) {
    const results = await Axios.post(
        `${import.meta.env.VITE_BACKEND_URL}${path}`,
        body,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("JWT")}`,
            },
        }
    );
    return results;
}

export async function authorizedBackendGet<T>(path: string, params?: any) {
    return await Axios.get<T>(`${import.meta.env.VITE_BACKEND_URL}${path}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
        params
    });
}

export async function authorizedBackendPut<T>(path: string, body: T) {
    return await Axios.put(
        `${import.meta.env.VITE_BACKEND_URL}${path}`,
        body,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("JWT")}`,
            },
        }
    );
}