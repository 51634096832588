import { zeroPad } from "@/components/InstructionalLog/utils";

export class Day {
    year: number;
    month: number;
    day: number;

    constructor(year: number, month: number, day: number) {
        this.year = year;
        this.month = month;
        this.day = day;
    }

    static fromString(s: string): Day {
        const [year, month, day] = s.split("-").map(Number);
        return new Day(year, month, day);
    }

    static fromDate(date: Date): Day {
        return new Day(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }

    toString(): string {
        return `${zeroPad(this.year)}-${zeroPad(this.month)}-${zeroPad(this.day)}`;
    }

    toJSON(): string {
        return this.toString();
    }

    static today(): Day {
        return Day.fromDate(new Date());
    }

    isWeekend(): boolean {
        return this.dayOfWeek() === 0 || this.dayOfWeek() === 6;
    }

    dayOfWeek(): number {
        return new Date(this.year, this.month - 1, this.day).getDay();
    }

    tomorrow(): void {
        const date = new Date(this.year, this.month - 1, this.day);
        date.setDate(date.getDate() + 1);
        this.year = date.getFullYear();
        this.month = date.getMonth() + 1;
        this.day = date.getDate();
    }

    valueOf(): number {
        return new Date(this.year, this.month - 1, this.day).valueOf();
    }

    callOverRange(end: Day, callback: (day: Day) => void): void {
        for (let d = this.clone(); d <= end; d.tomorrow()) {
            callback(d);
        }
    }

    clone(): Day {
        return new Day(this.year, this.month, this.day);
    }
}