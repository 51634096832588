import { useCallback, useRef, useState } from "react";
import classNames from "classnames";
import Comment from "./Comment";

type Props = {
    rowNumber: number,
    colNumber: number,
    id: string,
    student_id: string,
    value: string | undefined,
    comment: string | undefined,
    log_id: string | undefined,
    onChange?: (id: string, log_id: string | undefined, value: string, comment?: string) => void,
    onBlur: (id: string, log_id: string | undefined, value: string, comment?: string) => void,
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    validationError?: string
}

const ErrorToolTip: React.FC<{ error: string }> = ({ error }) => {
    return <div className="absolute text-xs top-full z-50">
        <div className="bg-red-500 rounded top-full p-3 tooltiptext z-50"
            dangerouslySetInnerHTML={{ __html: error }} />
    </div>
}

const LogCell: React.FC<Props> = ({ rowNumber, colNumber, id, log_id, student_id, value, comment, onChange, validationError, onBlur, onKeyPress }) => {
    const [currentValue, setCurrentValue] = useState(value ?? "");
    const [currentComment, setCurrentComment] = useState(comment);
    const isDirty = useRef(false);
    const editingChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === currentValue) {
            return;
        }
        setCurrentValue(e.target.value);
        onChange?.(id, log_id, e.target.value);
        isDirty.current = true;
    }, [id, log_id, onChange, currentValue]);
    if (onChange) {
        return <>
            {validationError && <ErrorToolTip error={validationError} />}
            <Comment day={id} student_id={student_id} log_id={log_id} comment={currentComment ?? ""}
                onSubmit={(id, log_id, comment) => {
                    if (!isDirty.current) {
                        return;
                    }
                    isDirty.current = false;
                    onBlur?.(id, log_id, currentValue, comment);
                }}
                onChange={(_id, _log_id, comment) => {
                    isDirty.current = true;
                    setCurrentComment(comment ?? "");
                }}
            />
            <input data-row={rowNumber} data-col={colNumber} className={
                classNames(`w-full text-center bg-transparent p-1.5 text-sm`,
                    { "border-red-500": validationError }
                )}
                value={currentValue} onChange={editingChange}
                onBlur={(e) => {
                    if (!isDirty.current) {
                        return;
                    }
                    isDirty.current = false;
                    onBlur(id, log_id, e.target.value, currentComment);
                }}
                onKeyDown={onKeyPress}
            />
        </>;
    } else {
        return <div>{currentValue}</div>
    }
}

export default LogCell;