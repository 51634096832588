import InstructorChooser from "@/components/InstructorChooser";
import ScoringObjectivesTable from "@/components/ScoringObjectivesTable";
import { useToast } from "@/components/ui/use-toast";
import { authorizedBackendGet, authorizedBackendPut } from "@/lib/backend";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useEffect, useRef, useState } from "react";
import { Navigate } from "react-router";

type SubmitStatus = {
    status: "success"
} | {
    status: "error",
    message: string
}

function InstructorScoring() {
    const { nativeInstructorScoring } = useFlags();
    const [instructors, setInstructors] = useState<any[]>([]);
    const [selectedInstructorId, setSelectedInstructorId] = useState<string | null>(null);
    const [submitStatus, setSubmitStatus] = useState<SubmitStatus | null>(null);
    const objectiveStart = useRef<HTMLDivElement>(null);
    const { toast } = useToast()

    const selectedInstructor = instructors.find((instructor) => instructor.instructor.user_id === selectedInstructorId);

    useEffect(() => {
        authorizedBackendGet<{ instructors: any[] }>("/instructors").then((res) => {
            setInstructors(res.data.instructors);
        });
    }, [setInstructors])

    const onInstructorChange = (instructor_id: string) => {
        setSelectedInstructorId(instructor_id);
        setSubmitStatus(null);
    };

    const saveScore = useCallback((answers: any) => {
        authorizedBackendPut(`/instructors/scoring/${selectedInstructorId}`, {
            answers,
        }
        ).then((res) => {
            if (res.status === 200) {
                setSubmitStatus({ status: "success" });
                setSelectedInstructorId(null);
            } else {
                setSubmitStatus({ status: "error", message: res.data.message });
                toast({
                    title: "Unable to Save",
                    description: res.data.message,
                })
            }
            console.log(res);
        }).catch((err) => {
            setSubmitStatus({ status: "error", message: err.message });
            toast({
                title: "Unable to Save",
                description: err.message,
            })
        });
    }, [selectedInstructorId]);

    if (!nativeInstructorScoring) {
        // Feature is not enabled, redirect to home
        return <Navigate to="/" />;
    }

    if (!instructors) {
        return <div>Loading...</div>
    }

    return <div className="p-6">
        <div ref={objectiveStart} className="instructions text-balance max-w-3xl mb-6">Select "Always" if your observations lead you to believe
            that the instructor always does what that objective calls for. Select "Sometimes" if the instructor is
            not doing what that objective calls for consistently yet. Select "Not Yet" if the instructor never or only rarely
            does what the objective calls for.
        </div>
        <div className="flex flex-col mb-6">
            <div className="text-sm mb-3">Which instructor are you scoring? <span className="text-red-500">*</span></div>
            <div className="w-fit">
                <InstructorChooser instructors={instructors} onChange={onInstructorChange} value={selectedInstructor ? `${selectedInstructor.instructor.first_name} ${selectedInstructor.instructor.last_name}` : null} />
            </div>
        </div>

        {(!submitStatus || submitStatus.status !== "success") && (<div>
            <hr /> {/* TODO Add the fancy HR, find the proper brand color */}
            <h2 className="my-6 text-3xl">Objectives</h2>
            {submitStatus?.status === "error" && <div className="text-red-500 text-2xl mb-6">The Score has not been submitted yet</div>}
            <ScoringObjectivesTable onSubmit={saveScore} canSubmit={Boolean(selectedInstructorId)} onReset={() => {
                setSubmitStatus(null);
                objectiveStart.current?.scrollIntoView({ behavior: "smooth" });
            }} />
        </div>)}
        {submitStatus?.status === "success" && <div className="text-green-500 text-2xl">Score saved successfully</div>}
    </div>
}

export default InstructorScoring;