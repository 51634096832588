import { useQuery } from "@tanstack/react-query";
import { authorizedBackendGet } from "@/lib/backend";

export enum QuestionKind {
    Radio = 1,
    MutliSelect = 2,
    Text = 3,
}

export type Question = {
    id: number;
    question: string;
    kind: QuestionKind;
    is_required: boolean;
    options?: any; // Could probably be typed better
};

const QUERY_KEY = ["CoachingQuestions"];

const fetchObjectives = async (): Promise<Question[]> => {
    const { data } = await authorizedBackendGet<{ questions: Question[] }>("/questionsMetadata/1");
    return data.questions;
};

export const useCoachingQuestions = () => {
    return useQuery<Question[], Error>({
        queryKey: QUERY_KEY,
        queryFn: fetchObjectives,
        staleTime: 1000 * 60 * 60, // 1 hour
    });
};
