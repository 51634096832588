import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { authorizedBackendGet, authorizedBackendPost } from "@/lib/backend";
import { Day } from "@/lib/core/day";

export type InstructionalLogEntry = {
    date: Day;
    value: string;
    comment?: string;
    student_id: string;
    instructional_log_entry_id?: string;
}

type WireFormat = {
    entries: InstructionalLogEntry[],
};

type EntryMap = Map<string, InstructionalLogEntry>;

type InstructionalLogOutput = {
    entries: Map<string, EntryMap>
}


const QUERY_KEY = ["instructional_log"];

const convertToOutput = (entries: WireFormat): InstructionalLogOutput => {
    const entriesMap: Map<string, EntryMap> = new Map();
    entries.entries.forEach(entry => {
        if (!entriesMap.has(entry.student_id)) {
            entriesMap.set(entry.student_id, new Map());
        }
        const ds = entry.date.toString();
        entriesMap.get(entry.student_id)?.set(ds, entry);
    });

    return { entries: entriesMap };
}

const instructionalLog = async (school_id: string, date: Date): Promise<InstructionalLogOutput> => {
    const url = `/logs/instructional/${school_id}/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    const { data } = await authorizedBackendGet<WireFormat>(url);
    return convertToOutput(data)
};

export const useInstructionalLog = (school_id: string, date: Date) => {
    const queryClient = useQueryClient()

    const query = useQuery<InstructionalLogOutput, Error>({
        queryKey: QUERY_KEY.concat([school_id, date.toDateString()]),
        queryFn: () => instructionalLog(school_id, date),
        staleTime: 1000 * 60 * 60, // 1 hour,
        retry: false
    });
    const upsert = useMutation({
        mutationKey: ["upsert_instructional_log", school_id, date.toDateString()],
        mutationFn: async (entry: InstructionalLogEntry) => {
            const url = `/logs/instructional/${school_id}/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
            return await authorizedBackendPost<InstructionalLogEntry>(url, entry);
        },
        onMutate: async (newEntry: InstructionalLogEntry) => {
            await queryClient.cancelQueries({ queryKey: QUERY_KEY.concat([school_id, date.toDateString()]) });
            const previousEntries = queryClient.getQueryData<InstructionalLogOutput>(QUERY_KEY.concat([school_id, date.toDateString()]));
            queryClient.setQueryData<InstructionalLogOutput>(QUERY_KEY.concat([school_id, date.toDateString()]), (old?: InstructionalLogOutput) => {
                if (!old) {
                    return { entries: new Map([[newEntry.student_id, new Map([[newEntry.date.toString(), newEntry]])]]) };
                }
                const newEntries = new Map(old.entries);
                if (!newEntries.has(newEntry.student_id)) {
                    newEntries.set(newEntry.student_id, new Map());
                }
                newEntries.get(newEntry.student_id)?.set(newEntry.date.toString(), newEntry);
                return { entries: newEntries };
            });

            return { previousEntries };
        },
        onSettled: async () => {
            return await queryClient.invalidateQueries({
                queryKey: QUERY_KEY.concat([school_id])
            });
        },
        onError: (error, _newEntry, context) => {
            console.error("Error", error);
            queryClient.setQueryData<InstructionalLogOutput>(QUERY_KEY.concat([school_id, date.toDateString()]), context?.previousEntries);
        }
    });

    return { ...query, upsert };
};