import { Objective, useObjectives } from "@/hooks/useObjectives"
import { useState } from "react";
import { Button } from "./ui/button";

const ScoringObjectivesTable = ({ onOptionChange, onSubmit, onReset, canSubmit }: {
    onSubmit?: (answers: any) => void,
    onReset?: () => void,
    onOptionChange?: (objective_id: number, score: string) => void,
    canSubmit: boolean
}) => {
    const objectives = useObjectives();
    const [answers, setAnswers] = useState<{ [key: number]: number }>({});
    const isSubmitEnabled = canSubmit && Object.keys(answers).length > 0;

    const emptyObjectives: [Objective[], Objective[]] = [[], []];

    const [onboardingObjectives, otherObjectives]: [Objective[], Objective[]] = (objectives.data ?? []).reduce((acc, objective) => {
        if (objective.is_onboarding) {
            acc[0].push(objective as Objective);
        } else {
            acc[1].push(objective as Objective);
        }
        return acc;
    }, emptyObjectives);

    const updateAnswer = (objective_id: number, score: string) => {
        setAnswers((prev) => {
            return { ...prev, [objective_id]: Number.parseInt(score) }
        })
        if (onOptionChange) {
            onOptionChange(objective_id, score.toString());
        }
    }

    if (objectives.isLoading || objectives.isFetching) {
        return <div>Loading...</div>
    }

    if (objectives.isError) {
        // TODO: Look to see how error handling is normally done
        return <div>Error loading objectives {objectives.error.message}</div>
    }

    return (<div>
        <table className="relative w-full border">
            <thead>
                <tr className="bg-[#FFF7EF] sticky top-0 px-6 py-3">
                    <th className="">Onboarding Objectives</th>
                    <th className="sticky top-0 px-6 py-3 text-nowrap">Not Yet</th>
                    <th className="sticky top-0 px-6 py-3 text-nowrap">Sometimes</th>
                    <th className="sticky top-0 px-6 py-3 text-nowrap">Always</th>
                </tr>
            </thead>
            <tbody className="divide-y">
                {onboardingObjectives.map(objective => (<tr key={objective.id}>
                    <td className="px-6 py-3 max-w-4xl">{objective.objective}</td>
                    <td className="px-6 py-3 text-center"><input type="radio" name={`o-${objective.id}`} value={0} id="0" checked={answers[objective.id] === 0} onChange={(e) => { updateAnswer(objective.id, e.target.value) }} /></td>
                    <td className="px-6 py-3 text-center"><input type="radio" name={`o-${objective.id}`} value={1} id="1" checked={answers[objective.id] === 1} onChange={(e) => { updateAnswer(objective.id, e.target.value) }} /></td>
                    <td className="px-6 py-3 text-center"><input type="radio" name={`o-${objective.id}`} value={2} id="2" checked={answers[objective.id] === 2} onChange={(e) => { updateAnswer(objective.id, e.target.value) }} /></td>
                </tr>))}
            </tbody>
        </table>

        <table className="relative w-full border mt-6">
            <thead>
                <tr className="bg-[#FFF7EF] sticky top-0 px-6 py-3">
                    <th className=""></th>
                    <th className="sticky top-0 px-6 py-3 text-nowrap">Not Yet</th>
                    <th className="sticky top-0 px-6 py-3 text-nowrap">Sometimes</th>
                    <th className="sticky top-0 px-6 py-3 text-nowrap">Always</th>
                </tr>
            </thead>
            <tbody className="divide-y">
                {otherObjectives.map(objective => (<tr key={objective.id}>
                    <td className="px-6 py-3 max-w-4xl">{objective.objective}</td>
                    <td className="px-6 py-3 text-center"><input type="radio" name={`o-${objective.id}`} value={0} id="0" checked={answers[objective.id] === 0} onChange={(e) => { updateAnswer(objective.id, e.target.value) }} /></td>
                    <td className="px-6 py-3 text-center"><input type="radio" name={`o-${objective.id}`} value={1} id="1" checked={answers[objective.id] === 1} onChange={(e) => { updateAnswer(objective.id, e.target.value) }} /></td>
                    <td className="px-6 py-3 text-center"><input type="radio" name={`o-${objective.id}`} value={2} id="2" checked={answers[objective.id] === 2} onChange={(e) => { updateAnswer(objective.id, e.target.value) }} /></td>
                </tr>))}
            </tbody>
        </table>
        <div className="flex justify-end mt-6 gap-3">
            <Button variant="destructive" disabled={!isSubmitEnabled} size="lg" onClick={() => {
                setAnswers({});
                if (onReset) { onReset(); }
            }}>Reset</Button>
            <Button variant="default" size="lg" disabled={!isSubmitEnabled} onClick={() => {
                if (onSubmit) { onSubmit(answers); }
            }}>Submit</Button>
        </div>
    </div>)
};

export default ScoringObjectivesTable;