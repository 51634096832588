import { useQuery } from "@tanstack/react-query";
import { authorizedBackendGet } from "@/lib/backend";

export type Cycle = {
  slide_number: number;
  google_file_id: string;
  cycle: string;
};

const QUERY_KEY = ["Cycles"];

const fetchCycles = async (): Promise<Cycle[]> => {
  const { data } = await authorizedBackendGet<{ cycles: Cycle[] }>("/cycleMetadata");
  return data.cycles;
};

export const useCycles = () => {
  return useQuery<Cycle[], Error>({
    queryKey: QUERY_KEY,
    queryFn: fetchCycles,
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};
