import InstructorChooser from "@/components/InstructorChooser";
import QuestionCard from "@/components/Surveys/QuestionCard";
import QuestionEntry from "@/components/Surveys/QuestionEntry";
import { Button } from "@/components/ui/button";
import Loading from "@/components/ui/loading";
import { useCoachingQuestions } from "@/hooks/useCoachingQuestions";
import { useToast } from "@/components/ui/use-toast";
import { authorizedBackendGet, authorizedBackendPut } from "@/lib/backend";
import { useEffect, useState } from "react";

function isAnswered(value: any): boolean {
    if (value instanceof Array) {
        return value.length > 0;
    }
    if (value instanceof Set) {
        return value.size > 0;
    }
    return Boolean(value);
}

type Status = { status: "entering" } | { status: "submitting" } | { status: "submitted" } | { status: "error", error: string };
const ENTERING: Status = { status: "entering" };
const SUBMITTED: Status = { status: "submitted" };
const SUBMITTING: Status = { status: "submitting" };

function CoachingMeeting() {
    const [instructors, setInstructors] = useState<any[]>([]);
    const [selectedInstructorId, setSelectedInstructorId] = useState<string | null>(null);
    const [submitStatus, setSubmitStatus] = useState<Status>(ENTERING);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(true);
    const [answers, setAnswers] = useState<any>({});


    const questions = useCoachingQuestions()
    const { toast } = useToast()

    const selectedInstructor = instructors.find((instructor) => instructor.instructor.user_id === selectedInstructorId);

    useEffect(() => {
        authorizedBackendGet<{ instructors: any[] }>("/instructors").then((res) => {
            setInstructors(res.data.instructors);
        });
    }, [setInstructors])

    useEffect(() => {
        if (!selectedInstructorId) {
            setIsSubmitEnabled(false);
            return;
        }
        const requiredQuestions = questions.data?.filter((question) => question.is_required);
        const answeredQuestions = Object.entries(answers).filter((a: any) => isAnswered(a[1]))?.map((a) => a[0]) ?? [];
        setIsSubmitEnabled(requiredQuestions?.every((question) => answeredQuestions.includes(question.id.toString())) ?? false);
    }, [answers, questions])

    const onChange = (question_id: number, value: any) => {
        setSubmitStatus(ENTERING);
        if (value instanceof Set) {
            setAnswers((prev: any) => {
                return {
                    ...prev,
                    [question_id]: Array.from(value)
                }
            })
        } else {
            setAnswers((prev: any) => {
                return {
                    ...prev,
                    [question_id]: value
                }
            });
        }
    };

    const onSubmit = (answers: any) => {
        const surveyId = 1;
        const payload = {
            answers,
        }

        setSubmitStatus(SUBMITTING);

        authorizedBackendPut(`/surveys/${surveyId}/instructor/${selectedInstructorId}`, payload).then((res) => {
            console.log("response", res);
            setSelectedInstructorId(null);
            setAnswers({});
            setSubmitStatus(SUBMITTED);
            const inputs = document.querySelectorAll("input");
            inputs.forEach((input) => {
                if (input.type === "radio" || input.type === "checkbox") {
                    input.checked = false;
                } else {
                    input.value = "";
                }
            });
            const e = document.getElementById("form-top");
            e?.scrollIntoView({ behavior: "smooth" });
        }).catch((err) => {
            toast({
                title: "Unable to Save",
                description: err.message,
            })
            setSubmitStatus({ status: "error", error: `Unable to save responses: ${err.message}` });
            console.error(err);
        });
    };

    if (!instructors) {
        return <div>Loading...</div>
    }

    return (
        <div id="form-top" className={`bg-[#f2ebea] m-6`}>
            {submitStatus.status === SUBMITTING.status && <div className="fixed top-0 left-0 w-screen h-screen bg-white bg-opacity-75 flex items-center justify-center z-50"><div className="text-2xl">Submitting...</div></div>}
            {submitStatus.status === "error" && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 my-6 mx-3 rounded relative" role="alert">{submitStatus.error}</div>}
            {submitStatus.status === SUBMITTED.status && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 my-6 mx-3 rounded relative" role="alert">Meeting Summary Submitted</div>}
            <QuestionCard title={"Which Instructor"} required={true}>
                <InstructorChooser instructors={instructors} onChange={(instructor_id) => {
                    setSubmitStatus(ENTERING);
                    setSelectedInstructorId(instructor_id);
                }} value={selectedInstructor ? `${selectedInstructor.instructor.first_name} ${selectedInstructor.instructor.last_name}` : null} />
            </QuestionCard>
            <Loading {...questions}>
                {questions.data?.map((question) => {
                    return <QuestionCard key={question.id} title={question.question} required={question.is_required}>
                        <QuestionEntry answers={answers[question.id] ?? undefined} question={question} onChange={onChange} />
                    </QuestionCard>
                })}
            </Loading>
            {submitStatus.status === "error" && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 my-6 mx-3 rounded relative" role="alert">{submitStatus.error}</div>}
            <div className="flex justify-end mt-6 gap-3 mr-3 mb-3">
                <Button variant="default" size="lg" disabled={!isSubmitEnabled || submitStatus.status === SUBMITTING.status} onClick={(e) => {
                    e.preventDefault();
                    onSubmit(answers);
                }}>Submit</Button>
            </div>
        </div>
    )
}

export default CoachingMeeting;