import { Icons } from "../icons";

export interface ILoading {
    error: any;
    isError: boolean;
    isPending: boolean;
    isLoading: boolean;
}

type LoadingProps = React.PropsWithChildren<ILoading>;

const Loading: React.FC<LoadingProps> = ({ error, isError, isPending, isLoading, children }) => {
    if (isError) {
        return <div>Error: {error.message}</div>;
    }

    if (isPending) {
        return <div>Loading... <Icons.spinner className="h-4 w-4 animate-spin inline-block align-baseline" /></div>;
    }

    if (isLoading) {
        return <div>Loading... <Icons.spinner className="h-4 w-4 animate-spin inline-block align-baseline" /></div>;
    }

    return <>{children}</>;
};

export default Loading;