import { Question, QuestionKind } from "@/hooks/useCoachingQuestions"
import { useObjectives } from "@/hooks/useObjectives";
import Loading from "../ui/loading";
import QuestionEntryMultiselect from "./QuestionEntryMutliselect";

function isReference(value: any): boolean {
    return value && Boolean(value.reference);
}

const Radio = ({ question, answer, onChange }: { question: Question, answer: number | undefined, onChange: (question_id: number, answer: number) => void }) => {
    const objectives = useObjectives();
    if (isReference(question.options)) {
        return <Loading {...objectives}>
            <div className="grid grid-flow-row gap-3">
                <div className="flex justify-start items-start gap-1">
                    <input className="w-4 h-4 mr-2 mt-1" type="radio" checked={answer === -1} id="na" value={-1} name={`question-${question.id}`} onChange={() => { onChange(question.id, -1) }} />
                    <label className="max-w-prose" htmlFor="na">N/A</label>
                </div>
                {objectives.data?.filter((option) => !option.is_onboarding).map((option) => {
                    return <div key={option.id} className="flex justify-start items-start gap-1">
                        <input className="w-4 h-4 mr-2 mt-1" type="radio" checked={option.id === answer} id={`${option.id}`} value={option.id} name={`question-${question.id}`} onChange={() => { onChange(question.id, option.id) }} />
                        <label className="max-w-prose" htmlFor={`${option.id}`}>{option.objective}</label>
                    </div>
                })}</div>
        </Loading>
    }

    // This branch hasn't been tested yet
    return (<div>
        {question.options?.map((option: any) => {
            return <div key={option.id} className="flex justify-start items-center gap-1">
                {/* Move to use the ui input */}
                <input className="w-4 h-4 mr-2 mt-1" type="radio" id={`${option.id}`} name={`question-${question.id}`} onChange={() => { onChange(question.id, option.id) }} />
                <label className="" htmlFor={option.id}>{option.value}</label>
            </div>
        })}
    </div >);
}

const Text = ({ question, onChange }: { question: Question, answer: string | undefined, onChange: (question_id: number, value: string) => void }) => {
    return <input className="rounded border py-1 px-2 w-full" type="text" onChange={(e) => { onChange(question.id, e.target.value) }} />
};

const QuestionEntry = ({ question, answers, onChange }: { question: Question, answers: number[] | number | string | undefined, onChange: (question_id: number, question_answer: any) => void }) => {
    const stringAnswer = answers ? answers instanceof Array ? answers.map((a) => `${a}`).join(", ") : `${answers}` : undefined;
    const numberAnswers = answers instanceof Array ? answers[0] : answers as number;
    const arrayNumberAnswers = answers ? answers instanceof Array ? answers : [answers as number] : [];
    switch (question.kind) {
        case QuestionKind.Radio:
            return <Radio question={question} answer={numberAnswers} onChange={onChange} />;
        case QuestionKind.MutliSelect:
            return <QuestionEntryMultiselect answers={arrayNumberAnswers} question={question} onChange={onChange} />;
        case QuestionKind.Text:
            return <Text question={question} answer={stringAnswer} onChange={onChange} />;
    }
}

export default QuestionEntry;