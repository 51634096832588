
import Select from 'react-select'


const InstructorChooser = (props: {
    instructors: any[],
    onChange: (instructor_id: string) => void,
    value: string | null
}) => {
    const options = props.instructors.map((instructor) => {
        return { value: instructor.instructor.user_id, label: `${instructor.instructor.first_name} ${instructor.instructor.last_name}` }
    })

    return <Select
        value={props.value ? {
            value: props.value,
            label: props.value,
        } : {
            value: null,
            label: "Choose an instructor"
        }}
        options={options}
        styles={{
            control: (styles) => ({
                ...styles,
                width: "300px",
            }),
            option: (styles) => ({
                ...styles,
                width: "300px",
                color: "black"
            }),
            menu: (styles) => ({
                ...styles,
                width: "300px",
            }),
        }}
        onChange={value => {
            if (!value?.value) return
            props.onChange(value.value)
        }}
    />

}
export default InstructorChooser