import { Question } from "@/hooks/useCoachingQuestions"
import { useObjectives } from "@/hooks/useObjectives";
import Loading from "../ui/loading";

function isReference(value: any): boolean {
    return value && Boolean(value.reference);
}

const QuestionEntryMultiselect = ({ question, answers, onChange }: { question: Question, answers: number[], onChange: (question_id: number, answers: number[]) => void }) => {
    const objectives = useObjectives();
    const onOptionChange = (e: any) => {
        const option_id = parseInt(e.target.value);
        const newAnswerSet = new Set(answers);
        if (e.target.checked) {
            newAnswerSet.add(option_id);
        } else {
            newAnswerSet.delete(option_id);
        }
        const newAnswers = Array.from(newAnswerSet);
        onChange(question.id, newAnswers);
    };

    if (isReference(question.options)) {
        return <Loading {...objectives}>
            <div className="grid grid-flow-row gap-3">
                <div className="flex justify-start items-start gap-1">
                    <input className="w-4 h-4 mr-2 mt-1" type="checkbox" checked={answers.includes(-1)} id="na" value={-1} name={`question-${question.id}`} onChange={onOptionChange} />
                    <label className="max-w-prose" htmlFor="na">N/A</label>
                </div>
                {objectives.data?.filter((option) => !option.is_onboarding).map((option) => {
                    return <div key={option.id} className="flex justify-start items-start gap-1">
                        <input className="w-4 h-4 mr-2 mt-1" type="checkbox" id={`opt-${option.id}`} value={option.id} checked={answers.includes(option.id)} onChange={onOptionChange} />
                        <label className="max-w-prose" htmlFor={`opt-${option.id}`}>{option.objective}</label>
                    </div>
                })}</div>
        </Loading>
    }

    return (<div>
        {question.options?.map((option: any) => {
            return <div key={option.id} className="flex justify-start items-start gap-1">
                <input className="w-4 h-4 mr-2 mt-1" type="checkbox" id={option.id} onChange={onOptionChange} />
                <label htmlFor={option.id}>{option.option}</label>
            </div>
        })}
    </div >);
};

export default QuestionEntryMultiselect;