import { Day } from "@/lib/core/day";
import { CELL_CLASSES, TODAY_CELL_CLASSES } from "./LogRow";

type Props = {
    rowTitle: string,
    dates: Day[],
    holidays: Set<string>,
    log: { [date: string]: string },
    today: string
}

const AttendanceRow: React.FC<Props> = ({ rowTitle, log, dates, holidays, today }) => {
    return (
        <tr className="text-sm italic">
            <td className={`p-1.5 LogCell overflow-hidden text-left`} colSpan={3}>{rowTitle}</td>
            {dates.map(date => {
                const ds = date.toString();
                const value = log && log[ds];

                return <td className={today === ds ? TODAY_CELL_CLASSES : CELL_CLASSES} key={ds}>
                    {(!holidays?.has(ds)) && (value ? value : "0%")}
                </td>;
            })}
        </tr>
    );
};

export default AttendanceRow;