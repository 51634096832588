import { useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/icons";
import { useNavigate } from "react-router";
import { useState } from "react";
import { LocalStorageParams, SearchParams } from "@/main";
import { DEFAULT_CYCLE } from "@/consts";
import { createSearchParams } from "react-router-dom";
import Axios from "axios";

function Login() {
  const searchParams = new URLSearchParams(window.location.search);

  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const emailTextField = useRef<HTMLInputElement>(null);

  // const location = useLocation();
  const navigate = useNavigate();

  const handleGoogleClick = (e: any) => {
    e.preventDefault();
    setIsLoadingGoogle(true);
    const studentId = searchParams.get(SearchParams.StudentId);
    const cycle = searchParams.get(SearchParams.Cycle) ?? DEFAULT_CYCLE;

    // set our params in local storage before redirecting to google for auth
    localStorage.setItem(LocalStorageParams.Cycle, cycle);
    localStorage.setItem(LocalStorageParams.StudentId, studentId ?? "");

    window.location.assign(import.meta.env.VITE_STYTCH_GOOGLE_OAUTH_URL);
  };

  const handleEmailClick = (e: any) => {
    e.preventDefault();
    if (!emailTextField.current) {
      return;
    }

    const email = emailTextField.current.value;
    if (!email || email.length < 3) {
      return;
    }

    setIsLoadingEmail(true);
    const studentId = searchParams.get(SearchParams.StudentId);
    const cycle = searchParams.get(SearchParams.Cycle) ?? DEFAULT_CYCLE;

    // set our params in local storage before redirecting to google for auth
    localStorage.setItem(LocalStorageParams.Cycle, cycle);
    localStorage.setItem(LocalStorageParams.StudentId, studentId ?? "");

    Axios.post(
      `${import.meta.env.VITE_BACKEND_URL}/auth/stytch/magic_link`,
      {
        email: email,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      }
    )
      .then(() => {
        window.location.assign(`/magic_linked`);
      })
      .catch((error) => {
        setIsLoadingEmail(false);
        if (error.response.status === 409) {
          // 409 Means that the user is not allowed to use magic link
          alert("Please sign in with Google.");
          return;
        }
        if (error.response.status === 400) {
          if (error.response.data.error === "invalid_email") {
            alert("Please enter a valid email address.");
            return;
          }
        }

        if (error.response.status === 500) {
          if (error.response.data.error === "unknown_error") {
            alert("Sorry! Something went wrong. Please try again.");
            return;
          }
        }

        alert("Sorry! Something went wrong. Please try again.");
      });
  };

  useEffect(() => {
    const tokenFromUrl = searchParams.get(SearchParams.JWT);

    // If we have a token from the url then set it to local storage. This is 
    // used by authentication to check if the user is already logged in.
    if (tokenFromUrl !== null) {
      localStorage.setItem(LocalStorageParams.JWT, tokenFromUrl);

      // Redirect to the home page with the student id and cycle from local
      // storage. We set these to local storage in the handleClick (login) function. 
      const cycle = localStorage.getItem(LocalStorageParams.Cycle);
      const studentId = localStorage.getItem(LocalStorageParams.StudentId);

      return navigate({
        pathname: "/",
        search: createSearchParams({
          [SearchParams.Cycle]: cycle ?? "",
          [SearchParams.StudentId]: studentId ?? ""
        }).toString()
      });
    }
  }, [])

  return (
    <>
      <div className="container relative h-screen items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="relative hidden h-full flex-col place-items-center justify-center lg:flex">
          <div className="absolute inset-0 bg-ponce" />
          <div className="relative">
            <img id="google" src="/logo.png" alt="Once" style={{ width: "250px" }} />
          </div>
        </div>
        <div className="mx-auto flex w-full flex-col items-center justify-center space-y-6 sm:w-[350px]">
          <div className="border p-10 mt-10 flex flex-col">
            <p className="text-l font-semibold tracking-tight md:flex pb-8 w-[350px]">
              Instructors, please sign in with Google:
            </p>
            <Button
              className="w-[350px] border-gray-400"
              variant="outline"
              type="button"
              disabled={isLoadingGoogle}
              onClick={(e) => handleGoogleClick(e)}
            >
              {isLoadingGoogle ? (
                <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <Icons.google className="mr-2 h-4 w-4" />
              )}{" "}
              Google
            </Button>
          </div>
          <div className="border p-10 flex flex-col">
            <p className="text-m tracking-tight md:flex pb-8 w-[350px]">
              If you do not have a Google account, please sign in by entering
              your email:
            </p>
            <form
              className="flex flex-col"
              onSubmit={(e) => handleEmailClick(e)}
            >
              <input
                type="text"
                ref={emailTextField}
                className="border px-3 py-2 mb-5 w-[350px] border-gray-400"
                placeholder="email@example.com"
              />
              <Button
                className="w-[350px] border-gray-400"
                variant="outline"
                type="button"
                disabled={isLoadingEmail}
                onClick={(e) => handleEmailClick(e)}
              >
                Continue
              </Button>
            </form>
          </div>
        </div>
      </div>
      <div className="h-[900px] bg-zinc-100"></div>
    </>
  );
}

export default Login;