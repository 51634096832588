import { NavLink, NavLinkProps } from "react-router-dom";
import { authProvider } from "@/auth";
import { useFlags } from "launchdarkly-react-client-sdk";

/**
 *
 * Main navigation menu on the left side.
 */

function navigation(flags: any) {
  let nav = [
    authProvider.instructionalLogUrl
      ? {
        name: "Instructional Log",
        pathname: "/instructional-log",
      }
      : null,
    {
      name: "Today's Session",
      pathname: "/",
    },
    {
      name: "My Recordings",
      pathname: "/my-recordings",
    },
    {
      name: "Student Data",
      pathname: "/student-data",
    },
    {
      name: "Instructor Objectives",
      pathname: "/instructor-objectives",
    },
    {
      name: "Curriculum",
      pathname: "/curriculum",
    },
    flags.nativeInstructorScoring && authProvider.isCoach() ? {
      name: "Instructor Scoring",
      pathname: "/instructor-scoring",
    } : null,
    flags.nativeCoachingMeetingSummary && authProvider.isCoach() ? {
      name: "Coaching Meeting",
      pathname: "/coaching-meeting",
    } : null,
  ];

  return nav;
}

function Nav() {
  let flags = useFlags();
  let nav = navigation(flags);
  if (authProvider.userClassification === "Direct to Consumer Customer") {
    nav = nav.filter((item) => item?.name === "Curriculum");
  }

  return (
    <menu className="w-72 bg-ponce text-white flex flex-col">
      {nav.map((navItem) => {
        return (
          navItem && (
            <StyledNavLink
              key={navItem.pathname}
              to={{
                pathname: navItem.pathname,
                search: location.search,
              }}
            >
              {navItem.name}
            </StyledNavLink>
          )
        );
      })}
    </menu>
  );
}

export default Nav;

function StyledNavLink(props: NavLinkProps) {
  return (
    <NavLink
      className={({ isActive }) => {
        return `px-4 py-4 ${isActive ? "bg-white text-blue-700 " : ""} `
      }

      }
      to={props.to} >{props.children}</NavLink>
  )
}